import { FCX, ID, TextInput } from "@datassential/platform-ui-lib";
import React, { useEffect, useMemo, useState } from "react";
import { iUseMenuExamplesParams, useMenuExamples } from "@apiHooks/useMenuExamples";
import { useSelector } from "react-redux";
import {
    currentCountrySelector, currentCurrencySelector,
    currentCurrencyStringSelector
} from "@selectors/countries.selector";
import { SegmentsEnum } from "@models/ByoTableData";
import Tabs from "@components/Tabs/Tabs";
import Cell from "@components/NewAwesomeTable/Cell";
import LineEllipsis from "@components/LinesEllipsis/LinesEllipsis";
import NoMatches from "@components/NoMatches/NoMatches";
import { IoIosSearch } from "react-icons/io";
import VisibilitySensor from "react-visibility-sensor";
import DownloadBtn from "@components/DownloadBtn/DownloadBtn";

const SEGMENTS = [
    { id: SegmentsEnum.QSR, name: 'Quick Service' },
    { id: SegmentsEnum.FastCasual, name: 'Fast Casual' },
    { id: SegmentsEnum.Midscale, name: 'Midscale Dining' },
    { id: SegmentsEnum.CasualDining, name: 'Casual Dining' },
    { id: SegmentsEnum.FineDining, name: 'Fine Dining' },
];

const MenuExamplesResultsTable: FCX<{
    query: string;
    selectedStatesIds: ID[];
    isChainsEnabled: boolean;
}> = ({
    query,
    selectedStatesIds,
    isChainsEnabled,
}) => {
    const countryCode = useSelector(currentCountrySelector);
    const currencyValue = useSelector(currentCurrencyStringSelector);
    const currencySymbol = useSelector(currentCurrencySelector);

    const [selectedSegmentId, setSelectedSegmentId] = useState<ID>(SEGMENTS[0].id);
    const [additionalQuery, setAdditionalQuery] = useState<string>('');
    const [submittedAdditionalQuery, setSubmittedAdditionalQuery] = useState<string>('');

    const useMenuExamplesParams = useMemo(
        (): iUseMenuExamplesParams => ({
            search: query,
            statesIds: selectedStatesIds,
            selectedSegmentId,
            includeChains: isChainsEnabled,
            tableSearch: submittedAdditionalQuery,
            countryId: countryCode,
        }),
        [query, selectedStatesIds, isChainsEnabled, countryCode, submittedAdditionalQuery, selectedSegmentId]
    );

    const messageText = useMemo(
        () => {
            let result = `Dishes with <b>“${query.trim()}”</b>`;

            if (submittedAdditionalQuery.trim() !== '') {
                result += ` and <i>“${submittedAdditionalQuery.trim()}”</i>`;
            }

            return result;
        },
        [query, submittedAdditionalQuery]
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            setSubmittedAdditionalQuery(additionalQuery);
        }, 300);
        return () => clearTimeout(timer);
    }, [additionalQuery]);

    const {
        isLoading,
        data,
        inc,
        isLoaded,
        download,
    } = useMenuExamples(useMenuExamplesParams);

    return (
        <>
            <div
                className="MenuExamples__message"
            >
                <div
                    dangerouslySetInnerHTML={{ __html: messageText }}
                    data-testid={'textMenuExamplesSearchMessage'}
                />
                <div
                    className="MenuExamples__download"
                    data-testid="buttonMenuExamplesDownload"
                >
                    <DownloadBtn onClick={download}/>
                </div>
            </div>
            <div className="MenuExamples__results">
                <div className="MenuExamples__results-header">
                    <Tabs
                        data={SEGMENTS}
                        activeItemId={selectedSegmentId}
                        handleClick={setSelectedSegmentId}
                        testId="selectMenuExamplesSegments"
                    />
                    <TextInput
                        className="MenuExamples__results-search"
                        value={additionalQuery}
                        setValue={setAdditionalQuery}
                        placeholder="Search within results..."
                        testId="inputMenuExamplesTableSearch"
                    >
                        <IoIosSearch className="MenuExamples__results-search-icon"/>
                    </TextInput>
                </div>
                <div
                    className="MenuExamples__table"
                    data-testid="tableMenuExamplesResults"
                >
                    <div className="MenuExamples__table-head">
                        <Cell
                            className="MenuExamples__table-column"
                            options="head white"
                            data-testid="textMenuExamplesResultsTableHead-0"
                        >
                            Item
                        </Cell>
                        <Cell
                            className="MenuExamples__table-column"
                            options="head white"
                            data-testid="textMenuExamplesResultsTableHead-1"
                        >
                            Description
                        </Cell>
                        <Cell
                            className="MenuExamples__table-column"
                            options="head white"
                            data-testid="textMenuExamplesResultsTableHead-2"
                        >
                            Price {countryCode === "USA" ? "" : `(${currencyValue})`}
                        </Cell>
                        <Cell
                            className="MenuExamples__table-column"
                            options="head white center"
                            data-testid="textMenuExamplesResultsTableHead-3"
                        >
                            <div className="MenuExamples__table-cell-inner">
                                Venue
                            </div>
                        </Cell>
                    </div>
                    <div className="MenuExamples__table-body">
                        {data.map((item, index) => (
                            <div key={item.menuItemId} className="MenuExamples__table-row">
                                <Cell
                                    className="MenuExamples__table-column"
                                    options="white bold first high"
                                    data-testid={`textMenuExamplesResultsTableCell-${index}-0`}
                                >
                                    <LineEllipsis lines={3}>
                                        {item.menuItemName}
                                    </LineEllipsis>
                                </Cell>
                                <Cell
                                    className="MenuExamples__table-column"
                                    options="lighter no-pr high"
                                    data-testid={`textMenuExamplesResultsTableCell-${index}-1`}
                                >
                                    <div className="MenuExamplesPopup__text">
                                        {item && item.description && (
                                            <LineEllipsis lines={3}>
                                                {item.description}
                                            </LineEllipsis>
                                        )}
                                    </div>
                                </Cell>
                                <Cell
                                    className="MenuExamples__table-column"
                                    options="high"
                                    data-testid={`textMenuExamplesResultsTableCell-${index}-2`}
                                >
                                    <span className="MenuExamplesPopup__currency">
                                        {(item && item.price !== "-") ? currencySymbol : null}
                                    </span>
                                    {item && item.price}
                                </Cell>
                                <Cell
                                    className="MenuExamples__table-column"
                                    options="darken last high center"
                                    data-testid={`textMenuExamplesResultsTableCell-${index}-3`}
                                >
                                    {item && item.restaurantName}
                                </Cell>
                            </div>
                        ))}
                        {isLoading && Array(100).fill(null).map((_, index) => (
                            <div key={index} className="MenuExamples__table-row">
                                <Cell
                                    className="MenuExamples__table-column"
                                    options="white bold first high load"
                                />
                                <Cell
                                    className="MenuExamples__table-column"
                                    options="lighter no-pr high load"
                                />
                                <Cell
                                    className="MenuExamples__table-column"
                                    options="high load"
                                />
                                <Cell
                                    className="MenuExamples__table-column"
                                    options="darken last high center load"
                                />
                            </div>
                        ))}
                        {isLoaded && data.length === 0 && (
                            <NoMatches style={{
                                height: 400,
                            }}/>
                        )}
                    </div>
                    {!isLoaded && !isLoading && (
                        <VisibilitySensor
                            onChange={(visible) => {
                                if (visible) {
                                    inc();
                                }
                            }}
                            partialVisibility={true}
                        >
                            <div className="MenuExamples__table-loader"/>
                        </VisibilitySensor>
                    )}
                </div>
            </div>
        </>
    );
};

export default MenuExamplesResultsTable;