import React, { useState } from "react";
import { connect } from "react-redux";
import TooltipTabs from "../TooltipTabs/TooltipTabs";
import "./Tabs.scss";

const Tabs = (
    {
        data,
        title = '',
        activeItemId,
        handleClick,
        testId,
    }
) => {

    const [tooltipShownInx, setTooltipShownInx] = useState(null);

    return (
        <div
            className="tabs"
            data-testid={testId}
        >
            {title !== '' && <div className="tabs__title">{title}</div>}
            <div className="tabs__nav">
                {data.map((item, inx) => {
                    return (
                        <div
                            key={inx}
                            className={`tabs__item ${activeItemId === item.id ? 'tabs__item--active' : ''}`}
                            onClick={item.onClick || (() => handleClick(item.id))}
                            onMouseOver={() => setTooltipShownInx(inx)}
                            onMouseOut={() => setTooltipShownInx(null)}
                            data-testid={item.testId ?? `buttonTabsItem-${inx}`}
                        >
                            {item.name}
                            <TooltipTabs isShown={item.tooltip && tooltipShownInx === inx}>
                                <span>{item.tooltip}</span>
                            </TooltipTabs>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default connect(state => ({
    activeTabId: state.byo.activeAnalyticsType,
}))(Tabs);